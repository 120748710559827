<template>
  <div class="qrcode-cnt">
    <loader-wrapper v-if="isLoading" />
    <header class="inner-head">
      <div class="left-col">
        <span class="icon-btn" @click="$router.go(-1)">
          <BackIcon />
        </span>
        <h1>Qr Code</h1>
      </div>
    </header>
    <div class="step-tab">
      <span class="first-tab" :class="{ active: selectedTab === 'Step1' }"
        >Step 1</span
      >
      <span class="last-tab" :class="{ active: selectedTab === 'Step2' }"
        >Step 2</span
      >
    </div>
    <section class="mid-cnt">
      <div id="step1" class="qrtab-cnt" v-if="selectedTab == 'Step1'">
        <span class="qrtab-head">
          <h2>Order QR Codes</h2>
          <span class="status" v-if="isData"
            >Status:
            <strong
              :style="{ color: status == 'pending' ? '#ffbf00' : 'red' }"
              >{{ status.charAt(0).toUpperCase() + status.slice(1) }}</strong
            ></span
          >
        </span>
        <div class="form-box">
          <div class="field-row">
            <label class="text-label">{{ $t("_fullName") }}</label>
            <input
              name="name"
              :value="step1.name"
              class="input-style"
              placeholder="यहाँ अपना पूरा नाम लिखे"
              @input="getInput"
              :readonly="isData"
            />
            <error-text :txt="inputError['name']" />
          </div>

          <div class="field-row">
            <label class="text-label">{{ $t("_mobileNumber") }} </label>
            <div class="rel-box">
              <input
                name="mobileNo"
                :value="step1.mobileNo"
                class="input-style"
                placeholder="अपना 10 अंको वाला मोबाइल नंबर "
                @input="getInput"
                :readonly="isData"
              />
            </div>
            <error-text :txt="inputError['mobileNo']" />
          </div>

          <div class="field-row">
            <label class="text-label">{{ $t("_address") }}</label>

            <textarea
              name="address"
              class="textare-style"
              placeholder="यहाँ अपना पूरा एड्रेस लिखे"
              :value="step1.address"
              @input="getInput"
              :readonly="isData"
            ></textarea>
            <error-text :txt="inputError['address']" />
          </div>

          <div class="field-row">
            <label class="text-label">{{ $t("_quantity") }}</label>
            <div class="order-qty without-qty">
              <span
                class="qty-btn minus-value-btn"
                v-if="!isData"
                @click="
                  step1.quantity !== 1
                    ? (step1.quantity = step1.quantity - 1)
                    : 1
                "
                >-</span
              >
              <span class="qty-btn minus-value-btn" v-else>-</span>
              <input
                type="number"
                :placeholder="step1.quantity"
                disabled="disabled"
                autofocus="autofocus"
                class="input-qty"
              />
              <span
                class="qty-btn add-value-btn"
                v-if="!isData"
                @click="step1.quantity = step1.quantity + 1"
                >+</span
              >
              <span class="qty-btn add-value-btn" v-else>+</span>
            </div>
          </div>

          <footer class="footer-bottom">
            <button
              class="button"
              :class="{ isData: isData }"
              @click="handleOrder"
              :disabled="isData"
            >
              {{ $t("orderNow") }}
            </button>
          </footer>
        </div>
      </div>

      <div id="step2" class="qrtab-cnt" v-if="selectedTab == 'Step2'">
        <h2>
          {{ $t("qrCodeStep2Text") }}
        </h2>

        <div class="steps-box">
          <div class="step-title">
            <span class="check-point">
              <CheckMarkIcon />
            </span>
            <h2>{{ $t("howToRegister") }}</h2>
          </div>

          <div class="video-box">
            <iframe
              src="https://www.youtube.com/embed/R--aC7F55kA"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>

        <div class="steps-box">
          <div class="step-title">
            <span class="check-point">
              <CheckMarkIcon />
            </span>
            <h2>{{$t('howToAddBankDetails')}}</h2>
          </div>

          <div class="video-box">
            <iframe
              src="https://www.youtube.com/embed/ASy3UFpyOVw"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>

        <div class="steps-box">
          <div class="step-title">
            <span class="check-point">
              <CheckMarkIcon />
            </span>
            <h2>{{ $t("howToAddProduct") }}</h2>
          </div>

          <div class="video-box">
            <iframe
              src="https://www.youtube.com/embed/aufcyRrK1ts"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>

        <div class="steps-box">
          <div class="step-title">
            <span class="check-point">
              <CheckMarkIcon />
            </span>
            <h2>{{ $t("howToVerifyShop") }}</h2>
          </div>

          <div class="video-box">
            <iframe
              src="https://www.youtube.com/embed/Ht1A6F53KeU"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>

        <div class="acitve-shops">
          <h2>{{ $t("activatedShops") }}</h2>
          <shops-cards :shops="activeShops" />
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import BackIcon from "@/components/svgIcons/BackIcon";
import CheckMarkIcon from "@/components/svgIcons/CheckMarkIcon";
import ErrorText from "../components/commonUI/ErrorText.vue";
import { validateQrcodeTaskInputsStep1 } from "../utils/Validator";
import ApiRequest from "../services/ApiRequest";
import { BASE_API, LOCAL_SHOP_URL } from "../utils/constants";
import { GET, POST } from "../utils/HttpStatusCodeConst";
import LoaderWrapper from "../components/commonUI/LoaderWrapper.vue";
import { mapGetters } from "vuex";
import ShopsCards from "../components/ShopsCards.vue";

// import { QrcodeStream } from 'vue-qrcode-reader'
export default {
  name: "QrCode",
  components: {
    BackIcon,
    ErrorText,
    LoaderWrapper,
    CheckMarkIcon,
    ShopsCards,
  },
  data() {
    return {
      selectedTab: "Step1",
      isLoading: false,
      isData: false,
      status: "",
      step1: {
        name: "",
        mobileNo: "",
        address: "",
        quantity: 1,
      },
      inputError: {},
      activeShops: [],
    };
  },

  computed: {
    ...mapGetters({
      getUserInfo: "getUserInfo",
    }),
  },

  mounted() {
    this.getOrderStatus();
  },

  methods: {
    handleOrder() {
      let _this = this;
      this.inputError = validateQrcodeTaskInputsStep1(_this.step1);
      if (
        Object.keys(this.inputError).length === 0 &&
        this.inputError.constructor === Object
      ) {
        this.isLoading = true;
        let details = {
          ...this.step1,
          partner_id: this.getUserInfo.partner_id,
          pincode: this.getUserInfo.pincode,
        };
        ApiRequest(BASE_API, "/partner91/order/qrcode/", POST, details)
          .then((data) => {
            console.log(data);
            if (data && data.result && data.result.status) {
              this.$swal({
                icon: "success",
                text: `Successfully Ordered!!`,
              }).then((result) => {
                if (result.isConfirmed) {
                  this.$router.go();
                }
              });
            } else {
              this.$swal({
                icon: "error",
                text: `Something went wrong!!`,
              });
            }
            this.isLoading = false;
          })
          .catch((e) => {
            console.log(e);
            this.$swal({
              icon: "error",
              text: `Something went wrong!!`,
            });
            this.isLoading = false;
          });
      }
    },

    getActivatedShops() {
      this.isLoading = true;
      ApiRequest(LOCAL_SHOP_URL, "/shopService/get-my-referred-shops", GET)
        .then((data) => {
          this.activeShops = data;
          this.isLoading = false;
        })
        .catch((e) => {
          console.log(e);
          this.$swal({
            icon: "error",
            text: `Something went wrong!!`,
          });
          this.isLoading = false;
        });
    },

    getOrderStatus() {
      this.isLoading = true;
      ApiRequest(BASE_API, "/partner91/qrcode/", GET)
        .then((data) => {
          if (data && data.data) {
            let details = data.data[0];
            this.step1.name = details.partner_name;
            this.step1.mobileNo = details.mobile_number;
            this.step1.address = details.address;
            this.step1.quantity = details.quantity;
            this.status = details.status;
            if (details.status == "accepted" || details.status == "delivered") {
              this.selectedTab = "Step2";
              this.getActivatedShops();
            }
            if (details.status == "pending") {
              this.isData = true;
              this.$swal({
                text: "आपका आदेश अभी लंबित स्थिति में है।",
                icon: "info",
              });
            }
            if (details.status == "rejected") {
              this.$swal({
                icon: "info",
                text:
                  "आपका पिछला आदेश अस्वीकार कर दिया गया है। आप नया क्यूआर-कोड ऑर्डर कर सकते हैं।",
              });
            }
          }
          this.isLoading = false;
        })
        .catch((e) => {
          console.log(e);
          this.$swal({
            icon: "error",
            text: `Something went wrong!!`,
          });
          this.isLoading = false;
        });
    },

    getInput(_event) {
      this.step1[_event.target.name] = _event.target.value;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/qr-code.scss";
</style>
